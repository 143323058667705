import React from "react"
import { graphql } from "gatsby"
import ListNote from "@/components/ListNote"
import Layout from "@/components/Layout"

const IndexPage = ({ data, pageContext }) => {
    const { edges } = data.allMdx

    return (
        <Layout>
            <h1>Dungeon 23</h1>
            <ListNote edges={edges} />
        </Layout>
    )
}

export default IndexPage

export const query = graphql`
    query IndexTemplateQuery {
        allMdx(
            filter: {
                frontmatter: {
                    tags: { in: "dungeon23" }
                    publish: { ne: false }
                }
                fileAbsolutePath: { regex: "/vault/" }
            }
            limit: 10
            sort: { order: DESC, fields: fields___date }
        ) {
            edges {
                node {
                    ...postList
                }
            }
        }
    }
`
